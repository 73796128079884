
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className='bg-blue-200 pt-7 pb-24 px-5 rounded w-full'>
      <div className='block'>&copy;Eigo</div>

      <div className='flex flex-row gap-x-2 mt-3'>
        <div>{ t('Email') }:</div>
        <a
          href='mailto:hamarynet@gmail.com'
          className='block underline hover:text-fuchsia-900 cursor-pointer'
          target="_blank"
        >
          hamarynet@gmail.com
        </a>
      </div>
    </footer>
  )
}

export default Footer
