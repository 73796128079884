import React, {
  useEffect,
  useState,
} from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

// types
import {
  ExamType,
  UserAnswerType,
} from '../types'

export function Answer() {
  const { t } = useTranslation()
  const { id } = useParams()

  // states
  const [exam, set_exam] = useState<ExamType>()
  const [score, set_score] = useState<number>()
  const [total_score, set_total_score] = useState<number>(0)
  const [user_answers, set_user_answers] = useState<UserAnswerType[]>()

  useEffect(() => {
    type ResponseType = {
      data: {
        exam: ExamType,
      }
    }

    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/en/exams/api/get_exam/${id}`, {})
      .then((response: ResponseType) => {
        set_exam(response.data.exam)
      })
  }, [])

  const Questions = () => {
    return exam?.questions?.map((question, index) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const style: any = {}

      question.style.split('; ').map(style_ => {
        style[style_.split(': ')[0] ?? ''] = style_.split(': ')[1] ?? ''
      })

      if (question.tag === 'h2') {
        return (
          <h2 key={index} style={style}>
            {question.description}
          </h2>
        )
      } else {
        const Content = () => {
          const re = /___a([0-9]+)(b|ib)___/

          let description = question.description

          while (re.test(description)) {
            const number = description.match(re)?.[1]??''
            const block = description.match(re)?.[2]??''

            const user_answer = user_answers?.[parseInt(number)] ?? undefined

            let input = `<input type='text' name='${number}' value='${user_answer?.['user_answer']??""}' class='${block === 'b' ? 'block' : 'inline-block'} bg-white px-2 py-1.5 text-black border border-black border-solid w-fit' />`

            if (user_answer) {
              input = `<div class='${block === 'b' ? 'flex' : 'inline-flex'} flex-row items-center'>` +
                input +
                (user_answer.user_answer.toLocaleLowerCase() === user_answer.correct_answer.toLocaleLowerCase() ? "<div class='inline-block text-sky-800 text-bold mx-1'>○</div>" : `<div class='inline-block text-rose-800 text-bold mx-1'>× (${user_answer.correct_answer})</div>`) +
                "</div>"
            }

            description = description.replace(re, input)
          }

          return (<div style={style} dangerouslySetInnerHTML={{ __html: description }}></div>)
        }

        return (
          <React.Fragment key={index}>
            <Content />
          </React.Fragment>
        )
      }
    })
  }

  const Score = () => {
    if (total_score > 0) {
      return (
        <h3 className="mb-6">{t('Score')}: {score} / {total_score}</h3>
      )
    }
  }

  if (!exam) {
    return (<></>)
  }

  const on_submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form_data = new FormData(e.currentTarget)

    type ResponseType = {
      data: {
        score: number,
        total_score: number,
        user_answers: UserAnswerType[],
      }
    }

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/exams/api/answer/${id}`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: ResponseType) => {
        set_score(response.data.score)
        set_total_score(response.data.total_score)
        set_user_answers(response.data.user_answers)

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      })
      .catch(() => {
        alert(t('Failed'))
      })
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <h2 className="text-sky-800 border-t-0 border-r-8 border-l-8 border-b border-sky-800 border-solid text-center p-1.5 mt-3 mb-10">
        {exam.name}
      </h2>

      <Score />

      <form
        onSubmit={on_submit}
      >
        <Questions />

        <button
          type='submit'
          className={`bg-sky-800 hover:bg-sky-900 text-white cursor-pointer border border-solid border-black rounded block px-6 py-3 shadow-black shadow active:translate-y-0.5 active:shadow-none mx-auto mb-8 mt-10 ${score !== undefined ? 'hidden' : 'block'}`}
        >
          {t('Submit')}
        </button>

        <button
          type='button'
          onClick={() => { window.location.reload() }}
          className={`bg-rose-800 hover:bg-rose-900 text-white cursor-pointer border border-solid border-black rounded block px-6 py-3 shadow-black shadow active:translate-y-0.5 active:shadow-none mx-auto mb-8 mt-10 ${score !== undefined ? 'block' : 'hidden'}`}
        >
          {t('Reset')}
        </button>
      </form>
    </section>
  )
}
