import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const Aside = () => {
  const { t, i18n } = useTranslation()

  return (
    <aside className='flex flex-row justify-around items-center rounded w-full bg-emerald-300'>
      <Link
        to={`/${i18n.language}`}
        className='grow text-center py-2 px-1.5 hover:bg-emerald-700 hover:text-white hover:font-bold cursor-pointer'
      >
        { t('Exams') }
      </Link>

      <Link
        to="https://backend.hamary.co/wordcard/search"
        target="_blank"
        className='grow text-center py-2 px-1.5 hover:bg-emerald-700 hover:text-white hover:font-bold cursor-pointer'
      >
        { t('Wordcard') }
      </Link>
    </aside>
  )
}

export default Aside
