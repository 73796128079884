import React, {
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

// types
import { CategoryType } from '../types'

export function Index () {
  const { t, i18n } = useTranslation()

  // states
  const [categories, set_categories] = useState<CategoryType[]>([])

  useEffect(() => {
    type ResponseType = {
      data: {
        categories: CategoryType[],
      }
    }

    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/en/exams/api/get_categories`, {})
      .then((response: ResponseType) => {
        set_categories(response.data.categories)
      })
  }, [])

  const Categories = () => {
    return categories.map((category, index) => {
      const Exams = () => {
        return category?.exams?.map((exam, exam_index) => {
          return (
            <React.Fragment key={`${index}${exam_index}`}>
              <h2>{ exam.name }</h2>

              <div className="flex flex-row gap-x-3 mt-1 mb-6">
                <Link
                  to={`/${i18n.language}/answer/${exam.id}`}
                  onClick={ () => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    })
                  } }
                  className="block underline text-purple-800 hover:text-purple-900 hover:font-bold"
                >
                  { t('Answer sheet') }
                </Link>

                <Link
                  to={exam.question_pdf_url}
                  target="_blank"
                  className="block underline text-purple-800 hover:text-purple-900 hover:font-bold"
                >
                  { t('Question PDF file') }
                </Link>

                <Link
                  to={exam.answer_pdf_url}
                  target="_blank"
                  className="block underline text-purple-800 hover:text-purple-900 hover:font-bold"
                >
                  { t('Answer PDF file') }
                </Link>
              </div>
            </React.Fragment>
          )
        })
      }

      return (
        <React.Fragment key={index}>
          <h2 className='text-sky-800 border-t-0 border-r-8 border-l-8 border-b border-sky-800 border-solid text-center p-1.5 mb-5'>
            { category.name }
          </h2>

          <Exams />
        </React.Fragment>
      )
    })
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <Categories />
    </section>
  )
}
