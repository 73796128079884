import React from 'react'
import { useTranslation } from 'react-i18next'

export function PrivacyPolicy () {
  const { t } = useTranslation()

  return (
    <section className='p-2.5 lg:p-5'>
      <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1">{ t('Privacy policy') }</h2>
      <p className="mx-auto my-4">
        本サービスを提供するに当たり、ユーザーの個人情報の取扱いについて以下の通りにプライバシーポリシーを定めます。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">ユーザーの個人情報</h3>
      <p className="mx-auto my-4">
        本サービスを利用する際、ユーザーは一定の情報を提供する必要があります。それらの情報には、例えばIPアドレス、端末の種類、位置情報、生年月日、性別、ユーザー名、住所、本名、銀行口座番号、ビットコインアドレス、身分証、身分証の写真、メールアドレス、電話番号、パスワード、アカウント ID などが含まれます。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">個人情報の公開</h3>
      <p className="mx-auto my-4">
        本サービスではユーザー名、IDが公開されます。<br/>
        生年月日は公開されませんが、サービスの一部で生年月日を元にしたユーザーの年齢を公開することがあります。<br />
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">個人情報の利用目的</h3>
      <p className="mx-auto my-4">
        本サービスはユーザーの個人情報を以下の目的で利用します。<br/>
        サービスの提供、運営のため。<br/>
        ユーザーに必要な通知を行うため。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">個人情報の開示</h3>
      <p className="mx-auto my-4">
        ユーザーは本サービスに対し個人情報の開示を求める事ができます。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">個人情報の訂正および消去</h3>
      <p className="mx-auto my-4">
        ユーザーは、ユーザーの個人情報が誤った情報である場合には本サービスが定める手続きにより個人情報の訂正、追加または削除を請求することができます。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">プライバシーポリシーの変更</h3>
      <p className="mx-auto my-4">
        本サービスは、必要に応じて本プライバシーポリシーを変更する事ができます。その場合、メールアドレスを登録済みのユーザーに対してはメールで通知致します。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">お問い合わせ</h3>
      <p className="mx-auto my-4">
        本プライバシーポリシーに関するお問い合わせは、メールアドレスhamarynet@gmail.comまでお願い致します。
      </p>
    </section>
  )
}
