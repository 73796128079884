import React from 'react'
import {
  BrowserRouter,
} from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { createRoot } from 'react-dom/client'

import "./i18n/config"
import './index.scss'

// components
import Layout from "./layout/Layout"

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  setTimeout(() => {
    root.render(
      <React.StrictMode>
        <div className='min-h-screen'>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID??''}>
            <BrowserRouter>
              <Layout />
            </BrowserRouter>
          </GoogleOAuthProvider>
        </div>
      </React.StrictMode>
    )
  }, 50)
}
