
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <header className='w-full text-center text-5xl lg:text-6xl font-mono text-rose-800 font-bold py-8 px-5'>
      { t("Eigo exams") }
    </header>
  )
}

export default Header
