import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import ja from "./ja.json"
import en from "./en.json"
import zh_hant from "./zh_hant.json"
import zh_hans from "./zh_hans.json"

const resources = {
  "ja": {
    translation: ja,
  },
  "en": {
    translation: en,
  },
  "zh-hans": {
    translation: zh_hans,
  },
  "zh-hant": {
    translation: zh_hant,
  }
}

const options = {
  order: ['path'],
}

i18next
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    detection: options,
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false
    }
  })

export default i18next
