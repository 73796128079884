import { createSlice } from '@reduxjs/toolkit'

export interface AsideStateType {
  mobile_is_visible: boolean,
}

const initialState: AsideStateType = {
  mobile_is_visible: false,
}

export const asideSlice = createSlice({
  name: 'aside',
  initialState,
  reducers: {
    update_mobile_is_visible: (state, action) => {
      state.mobile_is_visible = action.payload
    },
  },
})

export const {
  update_mobile_is_visible,
} = asideSlice.actions

export default asideSlice.reducer
